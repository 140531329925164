<template>
  <div class="file_container">
    <!-- 关联建档 -->
    <van-tabs @click="onTypeChange" type="card" sticky>
      <!-- 身份证件 -->
      <van-tab title="身份证件">
        <div v-if="show === false && active === 0" class="fixed">
          <!-- 证件类型 -->
          <van-field
            readonly
            clickable
            label="证件类型"
            :value="cardTypeValue"
            @click="cardTypePickerShow = true"
            right-icon="arrow-down"
            class="picker"
          />
          <van-popup v-model="cardTypePickerShow" round position="bottom">
            <van-picker
              show-toolbar
              :columns="cardTypeList"
              @cancel="cardTypePickerShow = false"
              @confirm="cardTypeConfirm"
            />
          </van-popup>
          <!-- 证件号 -->
          <van-field
            v-model="cardNo"
            label="证件号"
            placeholder="请输入证件号"
            input-align="right"
            v-if="cardType != 10 || cardType == 3"
            @input="getCardNo"
            class="input"
          />
          <van-field
            v-model="wCardNo"
            label="证件号"
            v-else
            placeholder="请输入证件号"
            input-align="right"
            class="input"
          />
          <!-- 姓名 -->
          <van-field
            v-model="name"
            label="姓名"
            placeholder="请输入姓名"
            input-align="right"
            @input="getName"
            class="input"
          />
          <!-- 是否本人 -->
          <van-field
            readonly
            clickable
            label="是否本人"
            :value="isSelfValue"
            @click="isSelfPickerShow = true"
            right-icon="arrow-down"
            class="picker"
          />
          <van-popup v-model="isSelfPickerShow" round position="bottom">
            <van-picker
              show-toolbar
              :columns="isSelfList"
              @cancel="isSelfPickerShow = false"
              @confirm="isSelfConfirm"
            />
          </van-popup>
          <!-- 关系 -->
          <van-field
            readonly
            clickable
            label="关系"
            :value="familyValue"
            @click="familyPickerShow = true"
            right-icon="arrow-down"
            class="picker"
            v-if="isSelfValue === '否'"
          />
          <van-popup v-model="familyPickerShow" round position="bottom">
            <van-picker
              show-toolbar
              :columns="familyList"
              @cancel="familyPickerShow = false"
              @confirm="familyConfirm"
            />
          </van-popup>
        </div>
      </van-tab>
      <!-- 诊疗卡号 -->
      <van-tab title="诊疗卡号">
        <div v-if="show === false && active === 1" class="fixed">
          <!-- 手机号 -->
          <!-- 手机号 -->
          <van-field
            v-if="!show"
            v-model="telephone"
            label="诊疗卡号"
            placeholder="请输入诊疗卡号"
            input-align="right"
            @input="getTelephone"
            type="tel"
            maxlength="15"
            class="input"
          />
          <!-- 姓名 -->
          <van-field
            v-model="name"
            label="姓名"
            placeholder="请输入姓名"
            input-align="right"
            @input="getName"
            class="input"
          />
          <!-- 是否本人 -->
          <van-field
            readonly
            clickable
            label="是否本人"
            :value="isSelfValue"
            @click="isSelfPickerShow = true"
            right-icon="arrow-down"
            class="picker"
          />
          <van-popup v-model="isSelfPickerShow" round position="bottom">
            <van-picker
              show-toolbar
              :columns="isSelfList"
              @cancel="isSelfPickerShow = false"
              @confirm="isSelfConfirm"
            />
          </van-popup>
          <!-- 关系 -->
          <van-field
            readonly
            clickable
            label="关系"
            :value="familyValue"
            @click="familyPickerShow = true"
            right-icon="arrow-down"
            class="picker"
            v-if="isSelfValue === '否'"
          />
          <van-popup v-model="familyPickerShow" round position="bottom">
            <van-picker
              show-toolbar
              :columns="familyList"
              @cancel="familyPickerShow = false"
              @confirm="familyConfirm"
            />
          </van-popup>
        </div>
      </van-tab>
    </van-tabs>
    <!-- 初诊建档 -->
    <div v-if="show === true" class="fixed">
      <!-- 证件类型 -->
      <van-field
        readonly
        clickable
        label="证件类型"
        :value="cardTypeValue"
        @click="cardTypePickerShow = true"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="cardTypePickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="cardTypeList"
          @cancel="cardTypePickerShow = false"
          @confirm="cardTypeConfirm"
        />
      </van-popup>
      <!-- 证件号 -->
      <van-field
        v-model="cardNo"
        label="证件号"
        placeholder="请输入证件号"
        input-align="right"
        @input="getCardNo"
        @blur="getAge"
        v-if="cardType != 10 || cardType == 3"
        class="input"
      />
      <van-field
        v-model="wCardNo"
        label="证件号"
        v-else
        placeholder="请输入证件号"
        input-align="right"
        class="input"
      />
      <!-- 姓名 -->
      <van-field
        v-model="name"
        label="姓名"
        placeholder="请输入姓名"
        input-align="right"
        @input="getName"
        class="input"
      />
      <!-- 是否本人 -->
      <van-field
        readonly
        clickable
        label="是否本人"
        :value="isSelfValue"
        @click="isSelfPickerShow = true"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="isSelfPickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="isSelfList"
          @cancel="isSelfPickerShow = false"
          @confirm="isSelfConfirm"
        />
      </van-popup>
      <!-- 关系 -->
      <van-field
        readonly
        clickable
        label="关系"
        :value="familyValue"
        @click="familyPickerShow = true"
        right-icon="arrow-down"
        class="picker"
        v-if="isSelfValue === '否'"
      />
      <van-popup v-model="familyPickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="familyList"
          @cancel="familyPickerShow = false"
          @confirm="familyConfirm"
        />
      </van-popup>
      <!-- 类型 -->
      <van-field
        readonly
        clickable
        label="类型"
        :value="isAdultValue"
        @click="isAdultPickerShow = true"
        v-if="cardType != 10"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="isAdultPickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="isAdultList"
          @cancel="isAdultPickerShow = false"
          @confirm="isAdultConfirm"
        />
      </van-popup>
      <!-- 性别 -->
      <van-field
        readonly
        clickable
        label="性别"
        :value="sexValue"
        @click="sexPickerShow = true"
        placeholder="请选择"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="sexPickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="sexList"
          @cancel="sexPickerShow = false"
          @confirm="sexConfirm"
        />
      </van-popup>
      <!-- 民族 -->
      <van-field
        v-if="cardType != 10"
        readonly
        clickable
        label="民族"
        :value="nationValue"
        @click="nationPickerShow = true"
        placeholder="请选择"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="nationPickerShow" round position="bottom">
        <van-picker
          show-toolbar
          :columns="nationList"
          @cancel="nationPickerShow = false"
          @confirm="nationConfirm"
        />
      </van-popup>
      <!-- 出生日期 -->
      <van-field
        readonly
        clickable
        label="出生日期"
        :value="birthday"
        @click="datePickerShow = true"
        placeholder="请选择"
        right-icon="arrow-down"
        class="picker"
      />
      <van-popup v-model="datePickerShow" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="dateConfirm"
          @cancel="dateCancel"
        />
      </van-popup>
      <!-- 年龄 -->
      <van-field label="年龄" :value="age" readonly class="input" />
      <!-- 手机号 -->
      <van-field
        v-if="show"
        v-model="telephone"
        label="手机号"
        placeholder="请输入手机号"
        input-align="right"
        @input="getTelephone"
        type="tel"
        maxlength="11"
        class="input"
      />
      <!-- 监护人信息 -->
      <div v-if="isAdultValue === '儿童'">
        <!-- 监护人姓名 -->
        <van-field
          v-model="guardName"
          label="监护人姓名"
          placeholder="请输入监护人姓名"
          input-align="right"
          @input="getGuardName"
          class="input"
        />
        <!-- 监护人证件类型 -->
        <van-field
          readonly
          clickable
          label="监护人证件类型"
          :value="guardCardTypeValue"
          @click="guardCardTypePickerShow = true"
          right-icon="arrow-down"
          class="picker"
        />
        <van-popup v-model="guardCardTypePickerShow" round position="bottom">
          <van-picker
            show-toolbar
            :columns="guardCardTypeList"
            @cancel="guardCardTypePickerShow = false"
            @confirm="guardCardTypeConfirm"
          />
        </van-popup>
        <!-- 监护人证件号 -->
        <van-field
          v-model="guardCardNo"
          label="监护人证件号"
          placeholder="请输入监护人证件号"
          input-align="right"
          @input="getGuardCardNo"
          class="input"
        />
      </div>
      <!-- 地址 -->
      <van-field
        v-model="address"
        label="地址"
        type="textarea"
        placeholder="请输入详细地址"
        class="textarea"
        @input="getAddress"
      />
    </div>
    <van-notice-bar
      color="#1989fa"
      background="#ecf9ff"
      :scrollable="false"
      wrapable
      text="本院在线建档实行实名制，请确保您所填信息真实准确，否则无法完成建档"
    />
    <van-button type="primary" v-if="show === false" @click="queryFileClick"
      >关联已有就诊档案</van-button
    >
    <van-button type="info" @click="firstFileClick" v-if="show === false"
      >初诊建档</van-button
    >
    <van-button type="info" @click="fileClick" v-if="show === true"
      >立即建档</van-button
    >
    <van-button type="danger" @click="unbindUserClick" v-if="show === true"
      >解绑</van-button
    >
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import axios from "axios";
import wx from "weixin-js-sdk";

export default {
  name: "file",
  data() {
    return {
      active: 0,
      show: false,
      //证件类型
      cardType: "01",
      cardTypeValue: "",
      cardTypePickerShow: false,
      cardTypeList: [],
      cardTypeArray: [],
      cardTypeIndex: 0,
      // 证件号
      cardNo: "",
      wCardNo: "",
      // 姓名
      name: "",
      // 是否本人
      isSelf: "1",
      isSelfValue: "",
      isSelfPickerShow: false,
      isSelfList: ["是", "否"],
      isSelfArray: [
        { dict_value: "1", dict_label: "是" },
        { dict_value: "0", dict_label: "否" }
      ],
      isSelfIndex: 0,
      // 手机号码
      telephone: "",
      //类型
      isAdult: "1",
      isAdultValue: "",
      isAdultPickerShow: false,
      isAdultList: [],
      isAdultArray: [],
      isAdultIndex: 0,
      //家庭关系
      family: "",
      familyValue: "",
      familyPickerShow: false,
      familyList: [],
      familyArray: [],
      familyIndex: 0,
      // 性别
      sex: "",
      sexValue: "",
      sexPickerShow: false,
      sexList: [],
      sexArray: [],
      sexIndex: 0,
      // 民族
      nation: "01",
      nationValue: "",
      nationPickerShow: false,
      nationList: [],
      nationArray: [],
      nationIndex: 0,
      // 户籍
      census: "",
      censusValue: "",
      censusPickerShow: false,
      censusList: [],
      censusArray: [],
      censusIndex: 0,
      // 出生日期
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      birthday: "",
      datePickerShow: false,
      // 年龄
      age: 0,
      //监护人姓名
      guardName: "",
      //监护人证件类型
      guardCardType: "01",
      guardCardTypeValue: "",
      guardCardTypePickerShow: false,
      guardCardTypeList: [],
      guardCardTypeArray: [],
      guardCardTypeIndex: 0,
      //监护人证件号
      guardCardNo: "",
      // 地址
      address: "",
      info: {}
    };
  },
  created() {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    }
    this.getData();
    this.getCardType();
    this.isSelfValue = this.isSelfList[this.isSelfIndex];
    this.getFamily();
    this.getPatType();
    this.getSex();
    this.getNation();
    this.getCensus();
    this.getGuardCardType();
    if (this.$route.query.info != undefined) {
      this.info = JSON.parse(this.$route.query.info);
      this.show = true;
      this.cardNo = this.info.card_no;
      this.name = this.info.name;
      this.age = this.info.age;
      this.telephone = this.info.telephone;
      this.address = this.info.address;
    }
  },
  methods: {
    // 获取code
    getCode() {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        local
      )}&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;

      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code;
      this.login(code);
    },
    //获取openId
    async login(code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      var data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        // this.getUserInfo(res.data.accessToken, res.data.openid);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 解绑
    async unbindUser() {
      this.$toast.loading({
        duration: 0,
        message: "解绑中",
        forbidClick: true
      });
      var data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.info.patCardNo,
        recordId: this.info.id
      });
      const { data: res } = await formPost(
        "/wxapp/sysUserApp/sysUserApp/unbindUser",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 获取证件类型
    async getCardType() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "card_type" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.cardTypeArray = res.data;
        for (var i = 0; i < this.cardTypeArray.length; i++) {
          this.cardTypeList.push(res.data[i].dict_label);
        }
        this.cardTypeValue = this.cardTypeList[this.cardTypeIndex];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 获取家庭关系类型
    async getFamily() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "family_relation" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.familyArray = res.data;
        for (var i = 0; i < this.familyArray.length; i++) {
          this.familyList.push(res.data[i].dict_label);
        }
        this.familyValue = this.familyList[this.familyIndex];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取建档人类型
    async getPatType() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "pat_type" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.isAdultArray = res.data;
        for (var i = 0; i < this.isAdultArray.length; i++) {
          this.isAdultList.push(res.data[i].dict_label);
        }
        this.isAdultValue = this.isAdultList[this.isAdultIndex];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取建档人性别
    async getSex() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "sys_user_sex" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.sexArray = res.data;
        for (var i = 0; i < 2; i++) {
          this.sexList.push(res.data[i].dict_label);
        }
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取建档人民族
    async getNation() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "2.16.156.10011.2.3.3.3" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.nationArray = res.data;
        for (var i = 0; i < this.nationArray.length; i++) {
          this.nationList.push(res.data[i].dict_label);
        }
        this.nationValue = this.nationList[this.nationIndex];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取建档人户籍
    async getCensus() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "2.16.156.10011.2.3.3.2" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.censusArray = res.data;
        for (var i = 0; i < this.censusArray.length; i++) {
          this.censusList.push(res.data[i].dict_label);
        }
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 获取监护人证件类型
    async getGuardCardType() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      var data = qs.stringify({ dictType: "card_type" });
      const { data: res } = await formPost(
        "/system/dict/data/dictDataList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.guardCardTypeArray = res.data;
        for (var i = 0; i < this.guardCardTypeArray.length; i++) {
          this.guardCardTypeList.push(res.data[i].dict_label);
        }
        this.guardCardTypeValue = this.guardCardTypeList[
          this.guardCardTypeIndex
        ];
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //证件号关联建档
    async queryCardFile() {
      this.$toast.loading({
        duration: 0,
        message: "关联中",
        forbidClick: true
      });
      var data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        isSelf: this.isSelf,
        cardType: this.cardType,
        cardNo: this.cardType == 10 ? this.wCardNo : this.cardNo,
        patName: this.name,
        family: this.family,
        sourceType: "1",
        flag: 1
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/userInfoInHis",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
        this.show = true;
        if (this.cardNo.trim() === "" || this.cardNo.length !== 18) {
          this.getData();
          this.maxDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );
          this.currentDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          );
          this.age = 0;
        } else {
          this.sub(this.cardNo);
        }
      }
    },
    //手机号码关联建档
    async queryTelephoneFile() {
      this.$toast.loading({
        duration: 0,
        message: "关联中",
        forbidClick: true
      });
      var data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        patName: this.name,
        patMobile: this.telephone,
        isSelf: this.isSelf,
        family: this.family,
        sourceType: "1",
        flag: 2
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/userInfoInHis",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
        this.show = true;
      }
    },
    // 就诊卡号关联建档
    async queryCardNumFile() {
      this.$toast.loading({
        duration: 0,
        message: "关联中",
        forbidClick: true
      });
      var data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        cardNo: this.telephone,
        cardType: "08",
        isSelf: this.isSelf,
        sourceType: "1",
        patName: this.name
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/userInfoInHis",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
        this.show = true;
      }
    },
    //建档
    async file() {
      this.$toast.loading({
        duration: 0,
        message: "建档中",
        forbidClick: true
      });
      var data = qs.stringify({
        hospitalId: 100,
        openId: window.localStorage.getItem("openId"),
        // gzhOpenId: window.localStorage.getItem("openId"),
        name: this.name,
        age: this.age,
        telephone: this.telephone,
        sex: this.sex,
        isAdult: this.isAdult,
        cardType: this.cardType,
        cardNo: this.cardType == 10 ? this.wCardNo : this.cardNo,
        address: this.address,
        birthday: this.birthday,
        isSelf: this.isSelf,
        guardCardType: this.guardCardType,
        guardCardNo: this.guardCardNo,
        guardName: this.guardName,
        family: this.family,
        nation: this.nation,
        census: this.census,
        sourceType: "1",
        status: "0"
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/add",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 获取当天日期
    getData() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      let today = year + "-" + month + "-" + strDate;
      this.birthday = today;
    },
    sub(str) {
      var date = new Date();
      var year = date.getFullYear();
      this.age = parseInt(year) - parseInt(str.substring(6, 10));
      this.birthday =
        str.substring(6, 10) +
        "-" +
        str.substring(10, 12) +
        "-" +
        str.substring(12, 14);
      this.currentDate = new Date(
        str.substring(6, 10),
        str.substring(10, 12) - 1,
        str.substring(12, 14)
      );
      if (parseInt(str.slice(-2, -1)) % 2 == 1) {
        // 男
        this.sex = 1;
        this.sexValue = "男性";
      } else {
        // 女
        this.sex = 2;
        this.sexValue = "女性";
      }
    },
    // 查询建档类型切换
    onTypeChange(name) {
      this.show = false;
      this.address = "";
      this.active = name;
      //关联建档
      this.telephone = "";

      this.cardType = "01";
      this.cardTypeIndex = 0;
      this.cardTypeValue = this.cardTypeList[this.cardTypeIndex];
      this.cardTypePickerShow = false;

      this.cardNo = "";

      this.name = "";

      this.isSelf = "1";
      this.isSelfIndex = 0;
      this.isSelfValue = this.isSelfList[this.isSelfIndex];
      this.isSelfPickerShow = false;

      this.telephone = "";

      this.family = "";
      this.familyIndex = 0;
      this.familyValue = this.familyList[this.familyIndex];
      this.familyPickerShow = false;

      //初诊建档
      this.isAdult = "1";
      this.isAdultIndex = 0;
      this.isAdultValue = this.isAdultList[this.isAdultIndex];
      this.isAdultPickerShow = false;

      this.sex = "";
      this.sexValue = "";
      this.sexIndex = 0;
      this.sexPickerShow = false;

      this.nation = "01";
      this.nationIndex = 0;
      this.nationPickerShow = false;

      this.census = "";
      this.censusIndex = 0;
      this.censusPickerShow = false;

      this.maxDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.currentDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      this.getData();
      this.datePickerShow = false;
      this.age = 0;
      this.guardName = "";

      this.guardCardType = "";
      this.guardCardTypeIndex = 0;
      this.guardCardTypeValue = this.guardCardTypeList[this.guardCardTypeIndex];
      this.guardCardTypePickerShow = false;

      this.guardCardNo = "01";
    },
    cardTypeConfirm(value) {
      this.cardTypeValue = value;
      this.cardTypeIndex = this.cardTypeList.findIndex(item => {
        return item === value;
      });
      this.cardType = this.cardTypeArray[this.cardTypeIndex].dict_value;
      this.cardTypePickerShow = false;
    },
    getCardNo(value) {
      this.cardNo = value;
    },
    unbindUserClick() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否解绑？"
        })
        .then(() => {
          this.unbindUser();
        })
        .catch(() => {});
    },
    getAge() {
      if (this.cardNo.trim() === "" || this.cardNo.length !== 18) {
        this.getData();
        this.maxDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.currentDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.age = 0;
      } else {
        this.sub(this.cardNo);
      }
    },
    getName(value) {
      this.name = value;
    },
    isSelfConfirm(value) {
      this.isSelfValue = value;
      this.isSelfIndex = this.isSelfList.findIndex(item => {
        return item === value;
      });
      this.isSelf = this.isSelfArray[this.isSelfIndex].dict_value;
      if (this.isSelfValue === "是") {
        this.family = "";
      } else if (this.isSelfValue === "否") {
        this.family = "2";
      }
      this.isSelfPickerShow = false;
    },
    familyConfirm(value) {
      this.familyValue = value;
      this.familyIndex = this.familyList.findIndex(item => {
        return item === value;
      });
      this.family = this.familyArray[this.familyIndex].dict_value;
      this.familyPickerShow = false;
    },
    getTelephone(value) {
      this.telephone = value;
    },
    isAdultConfirm(value) {
      this.isAdultValue = value;
      this.isAdultIndex = this.isAdultList.findIndex(item => {
        return item === value;
      });
      this.isAdult = this.isAdultArray[this.isAdultIndex].dict_value;
      if (this.isAdultValue === "成人") {
        this.guardCardType = "";
      } else if (this.isAdultValue === "儿童") {
        this.guardCardType = "01";
      }
      this.isAdultPickerShow = false;
    },
    sexConfirm(value) {
      this.sexValue = value;
      this.sexIndex = this.sexList.findIndex(item => {
        return item === value;
      });
      this.sex = this.sexArray[this.sexIndex].dict_value;
      this.sexPickerShow = false;
    },
    nationConfirm(value) {
      this.nationValue = value;
      this.nationIndex = this.nationList.findIndex(item => {
        return item === value;
      });
      this.nation = this.nationArray[this.nationIndex].dict_value;
      this.nationPickerShow = false;
    },
    censusConfirm(value) {
      this.censusValue = value;
      this.censusIndex = this.censusList.findIndex(item => {
        return item === value;
      });
      this.census = this.censusArray[this.censusIndex].dict_value;
      this.censusPickerShow = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var time = y + "-" + m + "-" + d;
      var today = new Date().getFullYear();
      this.age = today - y;
      return time;
    },
    guardCardTypeConfirm(value) {
      this.guardCardTypeValue = value;
      this.guardCardTypeIndex = this.guardCardTypeList.findIndex(item => {
        return item === value;
      });
      this.guardCardType = this.guardCardTypeArray[
        this.guardCardTypeIndex
      ].dict_value;
      this.guardCardTypePickerShow = false;
    },
    getGuardName(value) {
      this.guardName = value;
    },
    getGuardCardNo(value) {
      this.guardCardNo = value;
    },
    getAddress(value) {
      this.address = value;
    },
    dateConfirm(value) {
      this.birthday = this.dateFormat(value);
      this.datePickerShow = false;
    },
    dateCancel() {
      this.datePickerShow = false;
    },
    // 关联建档
    queryFileClick() {
      if (!this.show) {
        if (this.active === 0) {
          if (this.cardType == "10") {
            if (this.wCardNo === "") {
              this.$toast.fail("证件号不能为空");
            } else if (
              !(
                /^[A-Z]{3}[0-9]{12}$/.test(this.wCardNo) ||
                /^9\d{17}$/.test(this.wCardNo)
              )
            ) {
              this.$toast.fail("证件号有误，请重新输入");
            } else if (this.name == "") {
              this.$toast.fail("请输入名字");
            } else {
              this.queryCardFile();
            }
          } else {
            if (this.cardNo === "") {
              this.$toast.fail("证件号不能为空");
            } else if (/[\W]/g.test(this.cardNo) && this.cardType != 3) {
              this.$toast.fail("证件号含有特殊符号，请重新输入");
            } else if (
              this.cardTypeValue === "居民身份证" &&
              !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                this.cardNo
              ) &&
              this.cardType != 3
            ) {
              this.$toast.fail("证件号有误，请重新输入");
            } else if (this.name === "") {
              this.$toast.fail("姓名不能为空");
            } else if (
              !/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.name) &&
              this.cardType != 3
            ) {
              this.$toast.fail("姓名含有特殊符号，请重新输入");
            } else {
              this.queryCardFile();
            }
          }
        } else if (this.active === 1) {
          if (this.telephone === "") {
            this.$toast.fail("诊疗卡号不能为空");
          } else if (this.name === "") {
            this.$toast.fail("姓名不能为空");
          } else {
            this.queryCardNumFile();
          }
        }
      }
    },
    async files(name, card) {
      let str = location.href.split("#")[0];
      let data = {
        url: str
      };
      const { data: res } = await axios.post(
        "/wxyb/wxapp/userBindRecord/userBindRecord/getSignature",
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );

      wx.config({
        debug: true,
        beta: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
        appId: "wxcdae494f7e9422e7", // 必填，公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: ["requestWxFacePictureVerify"]
      });
      wx.ready(() => {
        wx.invoke(
          "requestWxFacePictureVerify",
          {
            request_verify_pre_info:
              '{"name": "' + name + '", "id_card_number": "' + card + '"}'
          },
          ss => {
            console.log(ss);

            if (ss.err_code == 0) {
              var verify_identifier = ss.verify_result;
              // 开发者选择是否使用后台文档获取本次认证的其他信息
              // webToast('刷脸验证成功')
            } else {
              var ret = ss.err_msg;
              ret += " err_code: " + ss.err_code;
              // webToast('刷脸验证失败');
            }
          }
        );
      });
    },
    // 初诊建档
    firstFileClick() {
      this.show = true;
      if (this.cardNo.trim() === "" || this.cardNo.length !== 18) {
        this.getData();
        this.maxDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.currentDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        this.age = 0;
      } else {
        this.sub(this.cardNo);
      }
    },
    //立即建档
    fileClick() {
      if (this.show) {
        //成人
        if (this.isAdultValue === "成人") {
          if (
            (this.cardType == "10" && this.wCardNo == "") ||
            (this.cardNo === "" && this.cardType == "1")
          ) {
            this.$toast.fail("证件号不能为空");
          } else if (
            this.cardType != "10" &&
            this.cardType != 3 &&
            /[\W]/g.test(this.cardNo)
          ) {
            this.$toast.fail("证件号含有特殊符号，请重新输入");
          } else if (
            (this.cardType == "10" &&
              !(
                /^[A-Z]{3}[0-9]{12}$/.test(this.wCardNo) ||
                /^9\d{17}$/.test(this.wCardNo)
              )) ||
            (this.cardType == "01" &&
              !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
                this.cardNo
              ))
          ) {
            console.log(this.wCardNo, this.cardType);
            console.log(/^[A-Z]{3}[0-9]{12}$/.test(this.wCardNo));
            this.$toast.fail("证件号格式有误，请重新输入");
          } else if (this.name === "") {
            this.$toast.fail("姓名不能为空");
          } else if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.name)) {
            this.$toast.fail("姓名含有特殊符号，请重新输入");
          } else if (this.sex == "") {
            this.$toast.fail("性别不能为空");
          } else if (this.telephone === "") {
            this.$toast.fail("手机号不能为空");
          } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.telephone)) {
            this.$toast.fail("手机号有误，请重新输入");
          } else if (this.address === "") {
            this.$toast.fail("地址不能为空");
          } else {
            this.guardCardType = "";
            this.guardCardNo = "";
            this.guardName = "";

            this.files(this.name, this.cardNo);
          }
        }
        //儿童无证件号码
        else if (this.isAdultValue === "儿童" && this.cardNo === "") {
          if (this.name === "") {
            this.$toast.fail("姓名不能为空");
          } else if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.name)) {
            this.$toast.fail("姓名含有特殊符号，请重新输入");
          } else if (this.sexValue === "") {
            this.$toast.fail("请选择性别");
          } else if (this.telephone === "") {
            this.$toast.fail("手机号不能为空");
          } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.telephone)) {
            this.$toast.fail("手机号有误，请重新输入");
          } else if (this.guardName === "") {
            this.$toast.fail("监护人姓名不能为空");
          } else if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.guardName)) {
            this.$toast.fail("监护人姓名含有特殊符号，请重新输入");
          } else if (this.guardCardNo === "") {
            this.$toast.fail("监护人证件号不能为空");
          } else if (/[\W]/g.test(this.guardCardNo)) {
            this.$toast.fail("监护人证件号含有特殊符号，请重新输入");
          } else if (
            this.guardCardTypeValue === "居民身份证" &&
            !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
              this.guardCardNo
            )
          ) {
            this.$toast.fail("监护人证件号格式有误，请重新输入");
          } else if (this.address === "") {
            this.$toast.fail("地址不能为空");
          } else {
            this.cardType = "";
            this.file();
          }
        }
        //儿童有证件号码
        else if (this.isAdultValue === "儿童" && this.cardNo !== "") {
          if (/[\W]/g.test(this.cardNo)) {
            this.$toast.fail("证件号含有特殊符号，请重新输入");
          } else if (
            !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
              this.cardNo
            ) &&
            this.cardType === "01"
          ) {
            this.$toast.fail("证件号有误，请重新输入");
          } else if (this.name === "") {
            this.$toast.fail("姓名不能为空");
          } else if (!/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.name)) {
            this.$toast.fail("姓名含有特殊符号，请重新输入");
          } else if (this.sexValue === "") {
            this.$toast.fail("请选择性别");
          } else if (this.telephone === "") {
            this.$toast.fail("手机号不能为空");
          } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.telephone)) {
            this.$toast.fail("手机号有误，请重新输入");
          } else if (
            this.guardName !== "" &&
            !/^[\u4e00-\u9fa5a-zA-Z]+$/.test(this.guardName)
          ) {
            this.$toast.fail("监护人姓名含有特殊符号，请重新输入");
          } else if (
            this.guardCardNo !== "" &&
            /[\W]/g.test(this.guardCardNo)
          ) {
            this.$toast.fail("监护人证件号含有特殊符号，请重新输入");
          } else if (
            this.guardCardTypeValue === "居民身份证" &&
            this.guardCardNo !== "" &&
            !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
              this.guardCardNo
            )
          ) {
            this.$toast.fail("监护人证件号格式有误，请重新输入");
          } else if (this.address === "") {
            this.$toast.fail("地址不能为空");
          } else {
            if (this.guardCardNo === "") {
              this.guardCardType = "";
              this.file();
            } else {
              this.file();
            }
          }
        }
      }
    }
  }
};
</script>

<style scope>
.file_container {
  padding-bottom: 60px;
}

.file_container .fixed {
  margin-top: 80px;
}

.file_container .van-tabs__nav--card {
  margin: unset;
  border: unset;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.file_container .van-tabs__nav--card .van-tab {
  border: unset;
  color: #000;
  font-size: 36px;
  border-top: 1px solid #1989fa;
  border-bottom: 1px solid #1989fa;
}

.file_container .van-tabs__nav--card .van-tab.van-tab--active {
  background-color: #1989fa;
}

.file_container .van-cell {
  text-align: center;
  padding: unset;
  height: 100px;
  font-size: 36px;
  line-height: 100px;
}

.file_container .van-field__label {
  width: 7.2em !important;
}

.file_container .picker .van-field__label {
  text-align: left;
  margin-left: 30px;
  border-bottom: 1px solid #f5f5f5;
}

.file_container .picker .van-field__control {
  text-align: right;
  padding-right: 60px;
  border-bottom: 1px solid #f5f5f5;
}

.file_container .input .van-field__label {
  text-align: left;
  margin-left: 30px;
  border-bottom: 1px solid #f5f5f5;
}

.file_container .input .van-field__control {
  text-align: right;
  padding-right: 40px;
  border-bottom: 1px solid #f5f5f5;
}

.file_container .textarea {
  padding: 20px 0;
  height: 220px;
  line-height: 60px;
}

.file_container .textarea .van-field__label {
  height: 220px;
  text-align: left;
  margin-left: 30px;
}

.file_container .textarea .van-field__control {
  height: 220px;
  text-align: right;
  padding-right: 40px;
}

.file_container .van-picker-column {
  font-size: 40px;
}

.file_container .van-picker__cancel,
.file_container .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.file_container .van-icon {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 40px !important;
}

.file_container .van-button {
  width: 80%;
  margin: 30px auto;
  display: block;
  border-radius: unset;
  font-size: 40px !important;
  height: 100px;
}

.file_container .van-notice-bar {
  font-size: 34px;
  padding: 20px 30px;
  line-height: 60px;
}

.file_container .van-picker__toolbar {
  height: 150px;
}
</style>
